<template>
  <div>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-card class="text-center">
        <v-toolbar color="primary" dark flat>
          <v-card-title class="white--text">
            新規プロジェクト
          </v-card-title>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="close">mdi-close</v-icon>
        </v-toolbar>

        <v-text-field :label="`プロジェクト名（必須）`" outlined class="rounded mx-auto mt-12" height="50" v-model="projectName"
          style="width: 500px;" :rules="inputRules" required></v-text-field>

        <v-col cols="12" class="py-0 my-0">
          <v-textarea :label="`説明（任意）`" outlined hide-details="false" class="rounded mx-auto" height="80"
            v-model="projectDetail" style="width: 500px;"></v-textarea>
        </v-col>

        <v-col cols="12" class="py-2 justify-center">
          <p class="mb-0 mt-4 pt-2">タイプの選択</p>
          <div class="d-flex justify-center">
            <v-radio-group v-model="projectType" mandatory row>
              <v-sheet class="mx-4" width="150px">
                <v-icon style="font-size: 72px;" class="mx-8" color="green lighten-2">mdi-chart-line</v-icon>
                <v-radio label="回帰分析" value="Regression" class="mx-4"></v-radio>
                <p class="text-center caption mt-4 mb-0">データの関係性を調べて、未来の数値を予測します。</p>
              </v-sheet>

              <v-sheet class="mx-4" width="150px">
                <v-icon style="font-size: 72px;" class="mx-8" color="orange lighten-2">mdi-chart-pie</v-icon>
                <v-radio label="多項分類" value="Classification" class="mx-4"></v-radio>
                <p class="text-center caption mt-4 mb-0">データをいくつかのグループに分けて分類します。</p>
              </v-sheet>

              <v-sheet class="mx-4" width="150px">
                <v-icon style="font-size: 72px;" class="mx-8" color="blue lighten-2">mdi-chart-timeline-variant</v-icon>
                <v-radio label="時系列予測" value="Forecast" class="mx-4"></v-radio>
                <p class="text-center caption mt-4 mb-0">過去のデータから、未来のトレンドやパターンを予測します。</p>
              </v-sheet>
            </v-radio-group>
          </div>
        </v-col>

        <v-card-actions class="justify-center">
          <v-col cols="5">
            <v-btn block outlined @click="close">キャンセル</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn color="primary" class="white--text" block @click="submitForm">作成</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-dialog v-if="isProjectCreationBlocked" v-model="isProjectCreationBlocked" max-width="800">
      <v-alert :value="true" type="warning" class="mb-0">
        <v-row>
          <v-col cols="10">
            現在のプランでは、これ以上のプロジェクトを作成することはできません。管理者にお問い合わせください。
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" text @click="isProjectCreationBlocked = false">閉じる</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

    <loading v-if="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
import { decodeTime } from "ulidx";

const Loading = () => import("@/components/Loading.vue");

export default {
  name: "DialogCreateProject",
  components: { Loading },
  data() {
    return {
      projectName: "",
      projectDetail: "",
      projectType: "",
      loading: false,
      isProjectCreationBlocked: false,
      tmp: [],
    };
  },

  computed: {
    inputRules() {
      return [(v) => !!v || '必須項目です'];
    },
  },

  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.createProject();
      } else {
        console.log('バリデーションエラーがあります');
      }
    },
    async createProject() {
      try {
        this.loading = true;
        if (process.env.NODE_ENV === 'development') {
          console.log(
            this.$store.state.auth.login.customerId,
            this.$store.state.auth.login.sessionId
          );
        }

        const response = await axios.post(
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project`,
          {
            ProjectName: this.projectName,
            ProjectDetail: this.projectDetail,
            ProjectType: this.projectType,
          },
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              SessionId: this.$store.state.auth.login.sessionId,
              "Content-Type": "application/json",
            },
          }
        );

        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        const projectId = response.data.results.ProjectId;
        this.loading = false;
        this.tmp = this.$store.state.user_model.project;

        const data = {
          CustomerId: this.$store.state.auth.login.customerId,
          Detail: this.projectDetail,
          Name: this.projectName,
          ProjectId: projectId,
          Type: this.projectType,
          created: new Date(decodeTime(projectId)),
        };

        this.$store.dispatch("addProject", data);
        this.$store.commit("setProjectName", this.projectName);
        this.$store.commit("setClassType", this.projectType);
        this.$router.push(`/project/${projectId}`);
        this.$emit("closeEmit");
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 429) {
          this.isProjectCreationBlocked = true;
        }
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
